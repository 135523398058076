import Button from "@hellodarwin/core/lib/components/common/button";
import Div from "@hellodarwin/core/lib/components/common/div";
import Dropdown from "@hellodarwin/core/lib/components/common/dropdown";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import {
  AdminProfile,
  AdminRoles,
} from "@hellodarwin/core/lib/features/entities";
import getFormattedDate from "@hellodarwin/core/lib/features/helpers/get-formatted-date";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Refresh from "@hellodarwin/icons/dist/icons/Refresh";
import Avatar from "antd/es/avatar";
import Input from "antd/es/input";
import { MenuProps } from "antd/es/menu/menu";
import Modal from "antd/es/modal";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import HubspotUserButton from "../../components/admins/hubspot-user-buttons";
import AdminAuth0UserButtons from "../../components/auth0/admin-auth0-buttons";
import {
  deleteAdmin,
  fetchAdmins,
  refreshAdmins,
  selectAllAdmins,
  verifyAdmin,
} from "../../features/api/slices/admins-slice";
import { selectAuthentifiedUser } from "../../features/api/slices/global-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import theme from "../../theme";
import EditAdminModal from "./edit-admin-modal";

const AdminsPage = () => {
  const admins = useAppSelector(selectAllAdmins);
  const [dataSource, setDataSource] = useState(admins || []);
  const [value, setValue] = useState("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const me = useAppSelector(selectAuthentifiedUser);
  const [activeAdmin, setActiveAdmin] = useState<AdminProfile>();
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAdmins({ api, needsAuth0: true }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataSource(admins);
  }, [admins]);

  const handleVerify = (data: AdminProfile) => {
    dispatch(verifyAdmin({ api, data }));
  };
  const handleDelete = (data: AdminProfile) => {
    Modal.confirm({
      title: "Do you really want to delete this admin?",
      content:
        "Their auth0 account will also be deleted, and to add them back, you will need to verify them again. Data will still be synched with hubspot, but the user will not be able to connect.",
      icon: (
        <Delete
          width={24}
          height={24}
          style={{ flexShrink: 0, marginRight: 8 }}
        />
      ),
      okButtonProps: {
        style: {
          backgroundColor: theme.colors.red_1,
          color: theme.colors.white_1,
          borderRadius: "24px",
        },
      },
      okText: "Delete them",
      cancelText: "Don't delete",
      cancelButtonProps: {
        style: {
          backgroundColor: theme.colors.white_1,
          borderColor: theme.colors.grey_1,
          color: theme.colors.grey_1,
          borderRadius: "24px",
        },
      },
      async onOk() {
        return await dispatch(deleteAdmin({ api, data }));
      },
      onCancel() {},
    });
  };

  const handleEditModal = () => setEditModalOpen(!editModalOpen);
  const handleEdit = (data: AdminProfile) => {
    setActiveAdmin(data);
    handleEditModal();
  };

  const columns: ColumnsType<AdminProfile> = useMemo(() => {
    const columns: ColumnsType<AdminProfile> = [];

    columns.push({
      title: t("profile|profileForm.users.list.username"),
      key: "name",
      width: 250,
      render: (v) => (
        <Div flex="row" align="center" gap={8}>
          <Avatar shape="circle" size={50}>
            {v.first_name?.[0]}
          </Avatar>
          <Typography bold>
            {v.first_name} {v.last_name}
          </Typography>
        </Div>
      ),

      sorter: {
        compare: (a, b) => a.first_name.localeCompare(b.first_name),
        multiple: 4,
      },
    });

    columns.push({
      title: t("profile|profileForm.users.list.email"),
      dataIndex: "email",
      key: "email",
      sorter: {
        compare: (a, b) => (a?.email || "").localeCompare(b?.email || ""),
        multiple: 1,
      },
    });

    columns.push({
      title: "Last login",
      key: "last_login_at",
      render: (v) => {
        return v.last_login_at ? (
          <Typography elementTheme="caption" nowrap>
            {getFormattedDate(v.last_login_at)}
          </Typography>
        ) : (
          <Typography elementTheme="caption" nowrap>
            --/--/--
          </Typography>
        );
      },
      sorter: {
        compare: (a, b) =>
          (a?.last_login_at || "").localeCompare(b?.last_login_at || ""),
        multiple: 1,
      },
    });

    columns.push({
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      filters: AdminRoles.map((role) => ({
        text: role,
        value: role,
      })),
      onFilter: (value, record) => record.roles === value,
      sorter: {
        compare: (a, b) => (a?.roles || "").localeCompare(b?.roles || ""),
        multiple: 1,
      },
    });
    columns.push({
      title: "Hubspot Status",
      key: "hubspot",
      fixed: "right",
      width: 170,
      filters: [
        {
          text: "Hubspot is active",
          value: true,
        },
        {
          text: "No Hubspot",
          value: false,
        },
      ],
      onFilter: (value, record) => !!record.hubspot?.hubspot_id === value,
      render(value, record, index) {
        return <HubspotUserButton admin={record} />;
      },
    });
    columns.push({
      title: "Auth0 Status",
      key: "auth0",
      fixed: "right",
      width: 240,
      filters: [
        {
          text: "Auth0 is active",
          value: true,
        },
        {
          text: "No Auth0",
          value: false,
        },
      ],
      onFilter: (value, record) => !!record.google_auth_id === value,
      render(value, record, index) {
        return (
          <AdminAuth0UserButtons
            admin={record}
            destination="Admin"
            compactView
            handleVerify={handleVerify}
          />
        );
      },
    });

    columns.push({
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 60,
      fixed: "right",
      render: (_, record) => {
        const actionMenuItems: MenuProps["items"] = [
          {
            label: t("profile|profileForm.users.actions.delete", {
              name: record?.first_name,
            }),
            key: 1,
            onClick: () => {
              handleDelete(record);
            },
            disabled: !["SuperAdmin"].includes(me.roles),
          },
          {
            label: t("profile|profileForm.users.actions.edit", {
              name: record?.first_name,
            }),
            key: 2,
            onClick: () => {
              handleEdit(record);
            },
            disabled: !["SuperAdmin", "Admin"].includes(me.roles),
          },
          {
            label: t("profile|profileForm.users.actions.verify", {
              name: record?.first_name,
            }),
            onClick: () => {
              handleVerify(record);
            },

            key: 3,
            disabled: record.is_verified_admin,
          },
        ].filter(Boolean);

        return <Dropdown items={actionMenuItems} cta={{ size: 24 }} />;
      },
    });

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = () => {
    dispatch(refreshAdmins({ api }));
  };

  return (
    <PageLayout
      app="admin"
      tabTitle={"Admins"}
      title={"Admins"}
      actions={
        <Button
          size="auto"
          style={{ width: 32, height: 32, borderRadius: 4 }}
          headingIcon={<Refresh width={16} height={16} />}
          defaultStyle={theme.colors.white_1}
          onClick={handleRefresh}
        />
      }
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Admins",
        },
      ]}
    >
      <Div flex="column" gap={34}>
        <Div flex="row" justify="space-between" align="center">
          <Typography.Title level={5} bold nowrap>
            {t("profile|profileForm.users.title")}
          </Typography.Title>
          <Input
            style={{ maxWidth: 350 }}
            placeholder="Search Name"
            value={value}
            onChange={(e) => {
              const currValue = e.target.value;
              setValue(currValue);
              const filteredData = admins?.filter(
                (entry) =>
                  entry?.first_name?.includes(currValue) ||
                  entry?.last_name?.includes(currValue) ||
                  entry?.phone?.includes(currValue) ||
                  entry?.email?.includes(currValue)
              );
              setDataSource(filteredData);
            }}
          />
        </Div>

        <Div>
          <Table
            scroll={{ x: 1600 }}
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record?.admin_id}
            sticky
          />
        </Div>
      </Div>
      {!!activeAdmin && (
        <EditAdminModal
          open={editModalOpen}
          handleCancel={handleEditModal}
          admin={activeAdmin}
        />
      )}
    </PageLayout>
  );
};

export default AdminsPage;

