import Div from "@hellodarwin/core/lib/components/common/div";
import SeeMoreTags from "@hellodarwin/core/lib/components/common/see-more/see-more-tags";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { GrantProject } from "@hellodarwin/core/lib/features/entities";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import Button from "antd/es/button";
import Input from "antd/es/input";
import TextArea from "antd/es/input/TextArea";
import message from "antd/es/message";
import Popconfirm from "antd/es/popconfirm";
import Spin from "antd/es/spin";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../../app";
import {
  selectIsDirty,
  setIsGrantProjectDirty,
  updateGrantProject,
} from "../../../features/api/slices/grant-projects-slice";
import {
  fetchTagsByProjectId,
  generateGrantProjectTags,
  selectGrantProjectTagsEntities,
} from "../../../features/api/slices/grant-tags-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import theme from "../../../theme";
import { InputNumberDollars } from "../../projects/project-form";
import GrantTagsDrawer from "./grant-tags-drawer";

type GrantFormProjectProps = {
  grantProject: GrantProject;
  index: number;
  locale: string;
  handleUpdateGrantProject: (
    locale: string,
    index: number,
    grantProject: GrantProject
  ) => void;
  handleDeleteGrantProject: (locale: string, index: number, id: string) => void;
  saveFlag?: boolean;
};

const GrantFormProject = ({
  grantProject,
  index,
  locale,
  handleUpdateGrantProject,
  handleDeleteGrantProject,
  saveFlag,
}: GrantFormProjectProps) => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [shortDescription, setShortDescription] = useState(
    grantProject.short_description
  );
  const [location, setLocation] = useState(grantProject.location);
  const [amountFunded, setAmountFunded] = useState(grantProject.amount_funded);
  const [isGeneratingTags, setIsGeneratingTags] = useState(false);
  const projectTags = useAppSelector((state) =>
    selectGrantProjectTagsEntities(state, locale, grantProject.grant_project_id)
  );

  const isLoading = useAppSelector(
    (state) =>
      state.grantTags.grantProjectTags[locale]?.[grantProject.grant_project_id]
        ?.loading
  );

  const isDirty = useAppSelector((state: RootState) =>
    selectIsDirty(state, grantProject.grant_project_id, locale)
  );
  useEffect(() => {
    setShortDescription(grantProject.short_description);
    setLocation(grantProject.location);
    setAmountFunded(grantProject.amount_funded);
    dispatch(
      fetchTagsByProjectId({
        api,
        grantProjectId: grantProject.grant_project_id,
        locale: locale,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantProject.grant_project_id, grantProject.locale]);

  useEffect(() => {
    if (saveFlag && isDirty) {
      saveProject();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFlag]);

  const generateTags = async () => {
    try {
      setIsGeneratingTags(true);
      await dispatch(
        generateGrantProjectTags({
          api,
          grantProject,
        })
      ).unwrap();
      setIsGeneratingTags(false);
      message.success("Tags generated successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to generate tags");
    }
  };

  const saveProject = async () => {
    try {
      const grantProjectUpdate: GrantProject = {
        ...grantProject,
        short_description: shortDescription,
        location,
        amount_funded: amountFunded,
      };
      await dispatch(
        updateGrantProject({ api, grantProject: grantProjectUpdate })
      );

      message.success("Project saved successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to save project");
    }
  };

  const setIsDirty = () => {
    if (!isDirty) {
      dispatch(
        setIsGrantProjectDirty({
          grantProjectId: grantProject.grant_project_id,
          locale: grantProject.locale,
        })
      );
    }
  };

  const openDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <Div
      flex="column"
      style={{
        marginBottom: 32,
        paddingRight: 32,
        backgroundColor: theme.colors.grey_5,
        borderRadius: 8,
        padding: 16,
      }}
      key={index}
    >
      <Div flex="row" justify="space-between">
        <Typography elementTheme="h5" style={{ width: "fit-content" }}>
          {index + 1}
        </Typography>
        <Div style={{ width: "fit-content" }}>
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={generateTags}
            loading={isGeneratingTags}
            disabled={isGeneratingTags}
          >
            Generate tags
          </Button>
          <Button
            style={{ marginRight: 8 }}
            disabled={!isDirty}
            onClick={saveProject}
          >
            Save
          </Button>
          <Popconfirm
            title="Delete this grant project"
            description="Are you sure to delete this grant project ?"
            onConfirm={() =>
              handleDeleteGrantProject(
                locale,
                index,
                grantProject.grant_project_id
              )
            }
            okText="Delete"
            cancelText="No"
          >
            <Button
              type="primary"
              style={{ padding: 8 }}
              danger={true}
              icon={<Delete width={14} height={14} />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Div>
      </Div>
      <Div flex="column" gap={16}>
        <>
          <Typography elementTheme="body2">Short Description</Typography>
          <TextArea
            key={grantProject.grant_project_id}
            value={shortDescription}
            onChange={(e) => {
              setIsDirty();
              setShortDescription(e.target.value);
            }}
            style={{ minHeight: 80 }}
          />
        </>
        <Div flex="row" gap={16}>
          <Div flex="column">
            <Typography elementTheme="body2">Location</Typography>
            <Input
              value={location}
              onChange={(e) => {
                setIsDirty();
                setLocation(e.target.value);
              }}
            />
          </Div>
          <Div flex="column" style={{ width: 160 }}>
            <Typography elementTheme="body2">Amount Funded</Typography>
            <InputNumberDollars
              value={amountFunded}
              onChange={(e) => {
                if (e) {
                  setIsDirty();
                  setAmountFunded(e as number);
                }
              }}
            />
          </Div>
        </Div>
        <Div flex="column">
          <Div flex="row" justify="space-between" align="center">
            <Typography elementTheme="body2">Tags</Typography>
            <Button
              type="link"
              onClick={openDrawer}
              icon={<Edit width={15} height={15} />}
            >
              Edit Tags
            </Button>
          </Div>
          {isLoading ? (
            <Spin />
          ) : (
            <Div flex="row" gap={8} align="center" wrap="wrap">
              <SeeMoreTags
                limit={10}
                size="small"
                content={projectTags || []}
              />
            </Div>
          )}
        </Div>
      </Div>
      <GrantTagsDrawer
        visible={isDrawerVisible}
        onClose={closeDrawer}
        currentTags={projectTags || []}
        entityType="GrantProject"
        entityId={grantProject.grant_project_id}
        locale={locale}
      />
    </Div>
  );
};

export default GrantFormProject;

