import AppLayout from "@hellodarwin/core/lib/components/common/layout/app-layout";
import ErrorNotFound from "@hellodarwin/core/lib/components/error/notfound";
import Language from "@hellodarwin/core/lib/features/enums/language";
import { useAppData } from "@hellodarwin/core/lib/features/providers/app-provider";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { useEffect, useMemo } from "react";
import {
  Navigate as NavigateRoute,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app";
import MatchesActionModal from "./components/matches/matches-action-modal";
import ProgramsActionModal from "./components/programs/programs-action-modal";
import ProjectActionModal from "./components/projects/projects-action-modal";
import ProviderActionModal from "./components/providers/provider-action-modal";
import { useActionBar } from "./context/action-bar-provider";
import {
  me,
  selectAuthentifiedUser,
  selectIsLoading,
} from "./features/api/slices/global-slice";
import { useAdminApi } from "./features/api/use-admin-api";
import HeaderBar from "./layout/header-bar";
import Navigation from "./layout/navigation";
import AdminsPage from "./pages/admins-page";
import CompaniesPage from "./pages/companies-page";
import FormSubmissionSinglePage from "./pages/form-submission-single-page";
import FormsPage from "./pages/forms-page";
import FundingExplorer from "./pages/funding-explorer";
import FundingExplorerSingleGrantPage from "./pages/funding-explorer/single-grant";
import GinList from "./pages/gin";
import SingleGinPage from "./pages/gin/single-gin.tsx";
import GrantsPage from "./pages/grants-page";
import HdChatsPage from "./pages/hd-chats-page";
import ProgramsPage from "./pages/programs-page";
import ProjectsPage from "./pages/projects-page";
import ProvidersPage from "./pages/providers-page";
import ReviewsPage from "./pages/reviews-page";
import SearchGrantsPage from "./pages/search-grants-page";
import SettingsPage from "./pages/settings-page";
import SimilarGrantPage from "./pages/similar-grant-page";
import SingleCompanyPage, { CompanyContent } from "./pages/single-company-page";
import SingleCompanyProgramPage from "./pages/single-company-program-page";
import SingleFormPage from "./pages/single-form-page";
import SingleFormPageSubmissions from "./pages/single-form-page-submissions";
import CreateFormPage from "./pages/single-form-page/create-form-page";
import SingleFormPreviewPage from "./pages/single-form-preview-page";
import SingleGrantApplicationPage from "./pages/single-grant-application-page";
import SingleGrantContextPage from "./pages/single-grant-context-page";
import SingleGrantPage from "./pages/single-grant-page";
import CreateGrantPage from "./pages/single-grant-page/create-grant";
import SingleGrantProviderPage from "./pages/single-grant-provider-page";
import CreateGrantProviderPage from "./pages/single-grant-provider-page/create-grant-provider";
import SingleChatPage from "./pages/single-hd-chat-page";
import SingleProgramPage from "./pages/single-program-page";
import SingleProjectPage from "./pages/single-project-page";
import SingleProviderPage from "./pages/single-provider-page";
import UnverifiedAdminPage from "./pages/unverified-page";

const AdminApp = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const admin = useAppSelector(selectAuthentifiedUser);
  const { actionBar } = useActionBar();
  const { changeLanguage } = useLocale();
  const isLoading = useAppSelector(selectIsLoading);
  const isLoggedIn = useMemo(() => !!admin.admin_id, [admin]);

  const isAppLoading = useMemo(
    () => isLoading || !isLoggedIn,
    [isLoading, isLoggedIn]
  );

  useEffect(() => {
    if (isLoggedIn) {
      changeLanguage(Language.English);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    dispatch(me({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appData = useAppData();
  useEffect(() => {
    const { setHeader, setNav } = appData;

    setHeader(<HeaderBar />);
    setNav(<Navigation />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (admin.roles === "Unverified") return <UnverifiedAdminPage />;

  return (
    <>
      <AppLayout app={"admin"} isLoading={isAppLoading}>
        <Routes>
          <Route path="/" element={<NavigateRoute to="/projects" />} />
          <Route path="/projects" element={<ProjectsPage key={pathname} />} />
          <Route
            path="/projects/:id"
            element={<SingleProjectPage key={pathname} />}
          />
          <Route path="/providers" element={<ProvidersPage key={pathname} />} />
          <Route
            path="/providers/:providerId"
            element={<SingleProviderPage key={pathname} />}
          />
          <Route
            path={`/providers/:providerId/:defaultSection`}
            element={<SingleProviderPage key={pathname} />}
          />
          <Route path="/companies" element={<CompaniesPage key={pathname} />} />
          <Route
            path="/clients/:id"
            element={
              <SingleCompanyPage
                key={pathname}
                defaultTab={CompanyContent.Company}
              />
            }
          />
          <Route
            path="/companies/:id"
            element={
              <SingleCompanyPage
                key={pathname}
                defaultTab={CompanyContent.Company}
              />
            }
          />
          <Route
            path="/companies/:id/users"
            element={
              <SingleCompanyPage
                key={pathname}
                defaultTab={CompanyContent.Users}
              />
            }
          />
          <Route
            path="/companies/:id/projects"
            element={
              <SingleCompanyPage
                key={pathname}
                defaultTab={CompanyContent.Projects}
              />
            }
          />
          <Route
            path="/companies/:id/grants"
            element={
              <SingleCompanyPage
                key={pathname}
                defaultTab={CompanyContent.Grants}
              />
            }
          />
          <Route
            path="/companies/:id/submissions"
            element={
              <SingleCompanyPage
                key={pathname}
                defaultTab={CompanyContent.FormsSubmissons}
              />
            }
          />
          <Route
            path="/companies/:companyId/grants/:grantId"
            element={<SingleCompanyProgramPage key={pathname} />}
          />
          <Route path="/reviews" element={<ReviewsPage key={pathname} />} />
          <Route path="/programs" element={<ProgramsPage key={pathname} />} />
          <Route
            path="/programs/:programId"
            element={<SingleProgramPage key={pathname} />}
          />
          <Route path="/settings" element={<SettingsPage key={pathname} />} />
          <Route path="/admins" element={<AdminsPage key={pathname} />} />
          <Route path="*" element={<ErrorNotFound navigate={navigate} />} />
          <Route
            path="/grants"
            element={<GrantsPage key={pathname} defaultKey="grants" />}
          />
          <Route
            path="/grants/providers"
            element={
              <GrantsPage key={pathname} defaultKey="grants-providers" />
            }
          />
          <Route
            path="/grants/providers/create"
            element={<CreateGrantProviderPage key={pathname} />}
          />
          <Route
            path="/grants/providers/:id"
            element={<SingleGrantProviderPage key={pathname} />}
          />
          <Route
            path="/grants/applications"
            element={
              <GrantsPage key={pathname} defaultKey="grants-applications" />
            }
          />
          <Route
            path="/grant-application/:id"
            element={<SingleGrantApplicationPage key={pathname} />}
          />
          <Route
            path="/grants/create"
            element={<CreateGrantPage key={pathname} />}
          />

          <Route
            path="/grants/create/similar/:id"
            element={<SimilarGrantPage key={pathname} />}
          />
          <Route
            path="/grants/:id"
            element={<SingleGrantPage key={pathname} />}
          />
          <Route
            path="/grants/scrape-context/:id"
            element={<SingleGrantContextPage key={pathname} />}
          />
          <Route
            path="/funding-explorer"
            element={<FundingExplorer key={pathname} />}
          />
          <Route path="/gin" element={<GinList key={pathname} />} />
          <Route path="/gin/:id" element={<SingleGinPage key={pathname} />} />
          <Route
            path="/search-grants"
            element={<SearchGrantsPage key={pathname} />}
          />
          <Route
            path="/search-grants/:id"
            element={<FundingExplorerSingleGrantPage key={pathname} />}
          />
          <Route
            path="/funding-explorer/:id"
            element={<FundingExplorerSingleGrantPage key={pathname} />}
          />
          <Route
            path="/forms"
            element={<FormsPage key={pathname} defaultKey="forms" />}
          />
          <Route
            path="/forms/submissions"
            element={
              <FormsPage key={pathname} defaultKey="forms_submissions" />
            }
          />
          <Route
            path="/forms/submissions/:formSubmissionId"
            element={<FormSubmissionSinglePage key={pathname} />}
          />
          <Route
            path="/forms/:id"
            element={<SingleFormPage key={pathname} />}
          />
          <Route
            path="/forms/:id/submissions"
            element={<SingleFormPageSubmissions key={pathname} />}
          />
          <Route
            path="/forms/create"
            element={<CreateFormPage key={pathname} />}
          />
          <Route
            path="/forms/:id/preview"
            element={<SingleFormPreviewPage key={pathname} />}
          />
          <Route
            path="/chats/:id"
            element={<SingleChatPage key={pathname} />}
          />
          <Route
            path="/chats"
            element={<HdChatsPage key={pathname} defaultKey={"chats"} />}
          />
          <Route
            path="/chats/prompts"
            element={<HdChatsPage key={pathname} defaultKey={"prompts"} />}
          />
          <Route
            path="/chats/templates"
            element={<HdChatsPage key={pathname} defaultKey={"templates"} />}
          />
        </Routes>

        <>{actionBar}</>
      </AppLayout>
      <ProjectActionModal />
      <ProviderActionModal />
      <MatchesActionModal />
      <ProgramsActionModal />
    </>
  );
};

export default AdminApp;

