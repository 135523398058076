import { BankOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CalendarOutlined from "@ant-design/icons/CalendarOutlined";
import ProjectOutlined from "@ant-design/icons/ProjectOutlined";
import WarningOutlined from "@ant-design/icons/WarningOutlined";
import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import ProfileUsersList from "@hellodarwin/core/lib/components/profile/profile-users-list";
import {
  Company,
  Contact,
  Program,
  ProgramStatus,
} from "@hellodarwin/core/lib/features/entities";
import {
  copyToClipboard,
  getFormattedDate,
  getShortFormattedDate,
  getShortId,
} from "@hellodarwin/core/lib/features/helpers";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import { useTheme } from "@hellodarwin/core/lib/plugins/styled";
import { DefaultTheme } from "@hellodarwin/core/lib/theme";
import Edit from "@hellodarwin/icons/dist/icons/Edit";
import NewWindow from "@hellodarwin/icons/dist/icons/NewWindow";
import Button from "antd/es/button";
import { Col, Row } from "antd/es/grid";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import notification from "antd/es/notification";
import Select from "antd/es/select";
import Tabs, { TabsProps } from "antd/es/tabs";
import Typography from "antd/es/typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app";
import Auth0UserButtons from "../../components/auth0/contacts-auth0-buttons";
import BlockButton from "../../components/blocks/block-button";
import BlocksList from "../../components/blocks/blocks-list";
import Clipboard from "../../components/clipboard";
import { default as CompanyForm } from "../../components/companies/company-form";
import ContactsForm from "../../components/contacts/contacts-form";
import ProgramForm from "../../components/programs/program-form";
import { EMAIL_ALREADY_EXISTS_ERROR } from "../../components/programs/programs-action-modal/create-program-form";
import SubscriptionForm from "../../components/subscription/subscription-form";
import { fetchAdmins } from "../../features/api/slices/admins-slice";
import {
  getCreditBalance,
  selectCreditBalance,
} from "../../features/api/slices/billing-slice";
import {
  fetchCompany,
  selectCompanyById,
  updateCompany,
} from "../../features/api/slices/companies-slice";
import {
  fetchProgramById,
  selectActiveTab,
  selectProgramsId,
  setActiveTab,
  updateProgram,
} from "../../features/api/slices/programs-slice";
import {
  assignContact,
  createContact,
  deleteContact,
  fetchCompanyContacts,
  getContactByEmail,
  selectCompanyContacts,
  selectContactById,
  updateContact,
} from "../../features/api/slices/users-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import { getStatusColor } from "../../features/utils";
import { BlockContent, useBlocks } from "../../hooks/use-blocks";
import { AdminPagesForms } from "../single-project-page";
import AdminTag from "../single-project-page/admin-tag";
import { ChangeProgramDealModal } from "./change-program-deal-modal";
import MilestonesBlock from "./milestones-block";
import ProgramActions from "./program-actions";

const { Option } = Select;

const blocksInitialState: BlockContent[] = [
  {
    element: <ProgramForm setForms={() => null} />,
    icon: <ProjectOutlined />,
    label: "",
    visible: true,
    unique: false,
  },
  {
    element: <CompanyForm setForms={() => null} />,
    icon: <BankOutlined />,
    label: "",
    visible: true,
    unique: false,
  },
  {
    element: <></>,
    icon: <CalendarOutlined />,
    label: "",
    visible: true,
    unique: true,
  },
];

const SingleProgramPage = () => {
  const { t } = useTranslation();
  const { programId } = useParams<{ programId: string }>();
  const api = useAdminApi();
  const theme = useTheme() as DefaultTheme;

  const dispatch = useAppDispatch();
  const program = useAppSelector((state) =>
    selectProgramsId(state, programId || "")
  );

  const creditBalance = useAppSelector(selectCreditBalance);
  const [blocks, setBlock, toggleBlock, span] = useBlocks(blocksInitialState);
  const [forms, setForms] = useState<AdminPagesForms>({
    companyForm: undefined,
    programForm: undefined,
    contactForm: undefined,
  });
  const [openEditDealModal, setOpenEditDealModal] = useState(false);

  const activeTab = useAppSelector(selectActiveTab);
  const company = useAppSelector((state) =>
    selectCompanyById(state, program?.program_company_id || "")
  );
  const contact = useAppSelector((state) =>
    selectContactById(state, program?.program_contact_id || "")
  );
  const companyContacts = useAppSelector((state) =>
    selectCompanyContacts(state, company?.company_id || "")
  );

  useEffect(() => {
    if (!programId) return;
    dispatch(fetchAdmins({ api, needsAuth0: false }));
    dispatch(fetchProgramById({ api, programId: programId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (program && program.program_company_id) {
      dispatch(
        fetchCompany({ api, companyId: program?.program_company_id || "" })
      );
      dispatch(
        fetchCompanyContacts({
          api,
          companyId: program?.program_company_id || "",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program?.program_company_id]);

  useEffect(() => {
    if (activeTab !== "program") {
      dispatch(setActiveTab("program"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (company?.company_billing_customer_id) {
      dispatch(
        getCreditBalance({
          api,
          billingId: company?.company_billing_customer_id || "",
        })
      );
    }
    if (!!program && program.program_id !== "") {
      setBlock(
        0,
        <>
          <ProgramForm program={program} setForms={setForms} />
          <ContactsForm
            contact={contact}
            setForms={setForms}
            company={company}
            destination={"Client"}
          />
        </>
      );
      setBlock(2, <MilestonesBlock programId={programId || ""} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program, contact]);

  useEffect(() => {
    setBlock(
      1,
      <>
        <SubscriptionForm
          billingId={company?.company_billing_customer_id || ""}
          creditBalance={creditBalance}
          companyId={company?.company_id}
        />
        <CompanyForm company={company} setForms={setForms} isProgram />
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditBalance, company]);

  const handleSave = async () => {
    try {
      const programFormValues = forms.programForm?.getFieldsValue();
      const companyFormValues = forms.companyForm?.getFieldsValue();
      const contactFormValues = forms.contactForm?.getFieldsValue();

      if (
        programFormValues === undefined ||
        companyFormValues === undefined ||
        contactFormValues === undefined
      ) {
        return;
      }

      if (program) {
        const newProgram: Program = {
          ...program,
          ...programFormValues,
        };
        await dispatch(updateProgram({ api, updatedProgram: newProgram }));
      }

      const updatedCompany: Company = {
        ...company,
        ...companyFormValues,
        company_id: company?.company_id || "",
      };
      await dispatch(updateCompany({ api, company: updatedCompany }));

      const updatedContact: Contact = {
        ...contact,
        ...contactFormValues,
      };
      await dispatch(updateContact({ api, contact: updatedContact }));

      message.success("Saved!");
    } catch (e: any) {
      if (e.response.data.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        notification.error({
          message: `Email already in use!`,
          description:
            "Please use a new email or search for an existing client.",
          placement: "bottomLeft",
        });
        return;
      }

      message.error("Something went wrong, try again later!");
      console.error(e);
    }
  };

  const handleChange = (value: string) => {
    handleSave();
  };

  const handleContactDelete = async (contactId: string) => {
    return await dispatch(deleteContact({ api, contactId }));
  };

  const handleContactEdit = async (contact: Contact) => {
    return await dispatch(updateContact({ api, contact }));
  };

  const handleContactAdd = async (contact: Contact) => {
    const createContactResult = await dispatch(
      createContact({
        api,
        contact: contact,
        destination: "Client",
      })
    );
    if (createContact.rejected.match(createContactResult)) {
      const error = createContactResult.payload;
      if (error && error.error_code === EMAIL_ALREADY_EXISTS_ERROR) {
        Modal.confirm({
          title: "Contact already exists",
          icon: <ExclamationCircleOutlined />,
          okButtonProps: {
            style: {
              backgroundColor: theme.colors.purple_1,
              color: theme.colors.white_1,
              borderRadius: "24px",
            },
          },
          cancelButtonProps: {
            style: {
              backgroundColor: theme.colors.white_1,
              borderColor: theme.colors.grey_1,
              color: theme.colors.grey_1,
              borderRadius: "24px",
            },
          },
          content:
            "A contact with this email already exists. It has either been deleted or assigned to another company. Do you wish to assign it to this company?",
          async onOk() {
            contact.company_id = company?.company_id || "";
            return await dispatch(assignContact({ api, contact: contact }));
          },
          onCancel() {},
        });
      } else {
        message.error("Something went wrong, try again later!");
      }
    }
    return createContactResult;
  };

  const handleContactAssign = async (contact: Contact) => {
    contact.company_id = company?.company_id || "";
    return await dispatch(assignContact({ api, contact: contact }));
  };

  const handleEmailExists = async (email: string) => {
    if (companyContacts.find((c) => c.email === email)) {
      return Promise.reject("Email already exists");
    }
    return await dispatch(getContactByEmail({ api, email }));
  };

  const Header = () => (
    <Div flex="row" justify="space-between">
      <Div flex="row" gap={16}>
        <Button onClick={handleSave} size="small">
          Save
        </Button>
        <Select value={program?.program_status} onChange={handleChange}>
          {(Object.keys(ProgramStatus) as (keyof typeof ProgramStatus)[]).map(
            (key, index) => (
              <Option key={index} value={ProgramStatus[key]}>
                {ProgramStatus[key]}
              </Option>
            )
          )}
        </Select>
      </Div>

      <Div flex="row" align="center" gap={16} fitContent>
        {blocks.map((block, i) => (
          <BlockButton
            icon={block.icon}
            key={i}
            onClick={() => toggleBlock(i)}
            tooltip={block.label}
            active={block.visible}
            notification={block.notification}
          />
        ))}
      </Div>
    </Div>
  );

  const tabs: TabsProps["items"] = [
    {
      key: "program",
      label: t(`Program Brief`),
      children: <BlocksList span={span} blocks={blocks} actions={<Header />} />,
    },
    {
      key: "companyContacts",
      label: t(`Users`),
      children: (
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col span={24}>
            {company && company.company_id !== "" && (
              <ProfileUsersList
                users={companyContacts}
                contactId={program?.program_contact_id || ""}
                companyId={company.company_id}
                handleContactDelete={handleContactDelete}
                handleContactEdit={handleContactEdit}
                handleContactAdd={handleContactAdd}
                handleEmailExists={handleEmailExists}
                handleContactAssign={handleContactAssign}
                Auth0Buttons={Auth0UserButtons}
                isAdmin
                destination={"Client"}
                ClipboardComponent={({ copy, title }) => (
                  <Clipboard copy={copy} title={title} />
                )}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      {program && (
        <PageLayout
          title={
            <Div flex="row" gap={16}>
              <AdminTag
                onClick={() => {
                  copyToClipboard(getShortId(program?.program_id));
                  message.success("Copied!");
                }}
                color={"purple"}
                text={"Program: " + getShortId(program?.program_id || "")}
                tooltip={"Click to copy to clipboard"}
              />
              <AdminTag
                color={getStatusColor(program?.program_status || "")}
                text={program?.program_status || ""}
                tooltip={program?.program_status || ""}
              />

              {program?.program_activated_at && (
                <AdminTag
                  text={
                    "Activated " +
                    getShortFormattedDate(program?.program_activated_at || "")
                  }
                  tooltip={
                    getFormattedDate(program?.program_activated_at) || ""
                  }
                  color={"purple"}
                />
              )}
              {program?.program_canceled_at && (
                <AdminTag
                  text={
                    "Canceled " +
                    getShortFormattedDate(program?.program_canceled_at || "")
                  }
                  tooltip={`${program?.program_canceled_reason} // ${getFormattedDate(
                    program?.program_canceled_at
                  )}`}
                  color={"red"}
                />
              )}
              {program?.program_completed_at && (
                <AdminTag
                  text={
                    "Completed " +
                    getShortFormattedDate(program?.program_completed_at || "")
                  }
                  tooltip={`${getFormattedDate(program?.program_completed_at)}`}
                  color={"gold"}
                />
              )}
              <Div
                style={{
                  background: "white",
                  borderRadius: 10,
                  width: "fit-content",
                  padding: "0 10px",
                }}
                flex="row"
                align="center"
              >
                <Typography.Text>
                  {program.program_hs_deal_id
                    ? "Hubspot Deal ID: " + program.program_hs_deal_id
                    : "No deal for this program"}
                </Typography.Text>
                <Button
                  style={{ marginLeft: 8 }}
                  type="link"
                  icon={
                    <Edit
                      style={{ color: theme.colors.purple_1 }}
                      width={16}
                      height={16}
                    />
                  }
                  onClick={() => setOpenEditDealModal(true)}
                />
                {program.program_hs_deal_id ? (
                  <Button
                    type="link"
                    icon={
                      <NewWindow
                        style={{ color: theme.colors.purple_1 }}
                        width={16}
                        height={16}
                      />
                    }
                    href={program.hubspot_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                ) : (
                  <WarningOutlined style={{ fontSize: 24, color: "#fc3503" }} />
                )}
              </Div>
            </Div>
          }
          app="admin"
          breadcrumbs={[
            {
              breadcrumbName: "Home",
              path: "/",
            },
            {
              breadcrumbName: "Programs",
              path: "/programs",
            },
            {
              breadcrumbName: program?.program_name,
            },
          ]}
        >
          <Div flex="column" gap={16}>
            <Tabs
              items={tabs}
              activeKey={activeTab}
              tabBarExtraContent={<ProgramActions program={program} />}
              onChange={(key) => dispatch(setActiveTab(key))}
            />
          </Div>
          <ChangeProgramDealModal
            program={program}
            open={openEditDealModal}
            onClose={() => setOpenEditDealModal(false)}
          />
        </PageLayout>
      )}
    </>
  );
};
export default SingleProgramPage;

