import Div from "@hellodarwin/core/lib/components/common/div";
import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import GrantCard from "@hellodarwin/core/lib/components/grants/card";
import { FundingExplorerFilterValues } from "@hellodarwin/core/lib/components/grants/filter/types";
import FundingExplorerListPageLayout from "@hellodarwin/core/lib/components/grants/funding-explorer-list-page";
import FundingExplorerCompanyInfo from "@hellodarwin/core/lib/components/grants/funding-explorer/funding-explorer-company-info";
import useApplyFundingExplorerFilter from "@hellodarwin/core/lib/components/grants/funding-explorer/hooks/apply-filters";
import Loading from "@hellodarwin/core/lib/components/loading";
import {
  Company,
  NewTag,
  TagType,
} from "@hellodarwin/core/lib/features/entities";
import AdminQueryFundingExplorerProps from "@hellodarwin/core/lib/features/entities/admin-entities";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { usePagination } from "@hellodarwin/core/lib/features/providers/pagination-provider";
import { useTranslation } from "@hellodarwin/core/lib/plugins/i18n";
import {
  annualRevenue,
  companySize,
} from "@hellodarwin/core/src/components/forms/utils/company-infos";
import Collapse from "antd/es/collapse/Collapse";
import Empty from "antd/es/empty";
import Form from "antd/es/form";
import Pagination from "antd/es/pagination";
import Select from "antd/es/select";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import CompaniesSearch from "../../components/companies/companies-search";
import {
  fetchBestGrantProjects,
  selectBestGrantProjects,
} from "../../features/api/slices/grant-projects-slice";
import {
  fetchChildTags,
  selectActivityOptions,
  selectActivityTypeOptions,
  selectEligibleExpenseOptions,
  selectSectorOptions,
} from "../../features/api/slices/grant-tags-slice";
import {
  fetchGrantFinancingType,
  fetchGrantService,
  fetchProvinces,
  queryFundingExplorer,
  selectAllFundingExplorerGrants,
  selectGrantFinancingType,
  selectGrantService,
  selectGrantsIsLoading,
  selectProvinces,
} from "../../features/api/slices/grants-slice";
import {
  fetchIndustries,
  selectIndustriesSectors,
  selectIndustriesSubsectors,
} from "../../features/api/slices/tags-slice";
import { useAdminApi } from "../../features/api/use-admin-api";

const FundingExplorer = () => {
  const dispatch = useAppDispatch();
  const api = useAdminApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const grants = useAppSelector(selectAllFundingExplorerGrants);
  const hasGrants = useMemo(
    () => grants !== undefined && grants?.length > 0,
    [grants]
  );
  const isLoading = useAppSelector(selectGrantsIsLoading);

  const provinces = useAppSelector(selectProvinces);
  const [filterform] = Form.useForm<FundingExplorerFilterValues>();
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [company, setCompany] = useState<Company | null>(null);
  const financingType = useAppSelector(selectGrantFinancingType);
  const industries = useAppSelector(selectIndustriesSectors);
  const subindustries = useAppSelector((state) =>
    selectIndustriesSubsectors(state, selectedIndustries || [])
  );
  const services = useAppSelector(selectGrantService);
  const bestProjects = useAppSelector(selectBestGrantProjects);
  const sectorOptions = useAppSelector(selectSectorOptions);
  const activityTypeOptions = useAppSelector(selectActivityTypeOptions);
  const activityOptions = useAppSelector(selectActivityOptions);
  const eligibleExpenseOptions = useAppSelector(selectEligibleExpenseOptions);
  const [selectedSectors, setSelectedSectors] = useState<NewTag[]>([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<NewTag[]>(
    []
  );
  const [selectedActivities, setSelectedActivities] = useState<NewTag[]>([]);
  const [selectedEligibleExpenses, setSelectedEligibleExpenses] = useState<
    NewTag[]
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const { activePage, goToPage, clearAllParams, pageSize } = usePagination();

  useEffect(() => {
    dispatch(fetchGrantFinancingType({ api, locale: selectedLocale }));
    dispatch(fetchIndustries({ api, locale: selectedLocale }));
    dispatch(fetchProvinces({ api, locale: selectedLocale }));
    dispatch(fetchGrantService({ api, locale: selectedLocale }));
    dispatch(fetchBestGrantProjects({ api: api, locale: selectedLocale }));

    dispatch(
      fetchChildTags({
        api,
        parentIds: [],
        locale: selectedLocale,
        type: TagType.Sector,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale]);

  useEffect(() => {
    if (selectedSectors.length > 0) {
      const parentIds = selectedSectors.map((sector) => sector.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.ActivityType,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSectors, selectedLocale]);

  useEffect(() => {
    if (selectedActivityTypes.length > 0) {
      const parentIds = selectedActivityTypes.map((type) => type.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.Activity,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivityTypes, selectedLocale]);

  useEffect(() => {
    if (selectedActivities.length > 0) {
      const parentIds = selectedActivities.map((activity) => activity.tag_id);
      dispatch(
        fetchChildTags({
          api,
          parentIds,
          locale: selectedLocale,
          type: TagType.EligibleExpense,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActivities, selectedLocale]);

  useEffect(() => {
    applyFilter({
      isReset: true,
      isInit: true,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilter({
      isReset: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects]);

  useEffect(() => {
    applyFilter({
      isReset: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageSize]);

  const onPageChange = async (page: number, pageSize: number) => {
    goToPage(page, pageSize);
  };

  const onTextQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    applyFilter({
      isReset: true,
    });
  };

  const fetch = async () => {
    try {
      const filterValues = filterform.getFieldsValue();

      const queryProps: AdminQueryFundingExplorerProps = {
        locale: selectedLocale,
        page: activePage,
        limit: pageSize,
        companyId: undefined,
        selectedProjects,
        tagSectors: selectedSectors.map((tag) => tag?.tag_id),
        tagActivityTypes: selectedActivityTypes.map((tag) => tag?.tag_id),
        tagActivities: selectedActivities.map((tag) => tag?.tag_id),
        tagEligibleExpenses: selectedEligibleExpenses.map((tag) => tag?.tag_id),
        ...filterValues,
      };

      dispatch(queryFundingExplorer({ api, ...queryProps }));
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleTagChange = (
    values: string[],
    setSelected: React.Dispatch<React.SetStateAction<NewTag[]>>,
    options: { [key: string]: NewTag }
  ) => {
    const newTags = values.map((value) => options[value]);
    setSelected(newTags);
  };

  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedSectors,
    selectedActivityTypes,
    selectedActivities,
    selectedEligibleExpenses,
  ]);

  const onCompanySelect = (company: Company | null) => {
    if (company) {
      setCompany(company);
      if (company.industry_sector) {
        filterform.setFieldValue("industry", company.industry_sector);
        setSelectedIndustries(company.industry_sector!);
      }
      if (company.industry_subsector) {
        filterform.setFieldValue("subindustry", company.industry_subsector);
      }
      if (company.province) {
        filterform.setFieldValue("region", company.province);
      }
      if (company.annual_revenue) {
        filterform.setFieldValue("annualRevenue", company.annual_revenue);
      }
      if (company.size) {
        filterform.setFieldValue("companySize", company.size);
      }
      applyFilter();
    }
  };

  const removeCompany = () => {
    filterform.resetFields();
    setCompany(null);
  };

  const applyFilter = useApplyFundingExplorerFilter({
    fetch,
    form: filterform,
    selectedIndustries,
    setSelectedIndustries,
  });

  const resetFilter = () => {
    filterform.resetFields();
    setSelectedIndustries([]);
    setSelectedSectors([]);
    setSelectedActivityTypes([]);
    setSelectedActivities([]);
    setSelectedEligibleExpenses([]);
    setSelectedProjects([]);
    applyFilter();
    clearAllParams(0, 10);
  };

  const TagsCollapse = () => {
    return (
      <Collapse ghost style={{ width: "100%" }}>
        <Collapse.Panel header={"Tag Filters"} key="1">
          <Div flex="row" gap={8}>
            {[
              TagType.Sector,
              TagType.ActivityType,
              TagType.Activity,
              TagType.EligibleExpense,
            ].map((type, idx) => (
              <Div key={idx}>
                <Typography elementTheme="body3">
                  {type
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Typography>
                <Select
                  mode="multiple"
                  placeholder={`Select ${type
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (c) => c.toUpperCase())}(s)`}
                  style={{ width: "100%" }}
                  value={
                    type === TagType.Sector
                      ? selectedSectors.map((tag) => tag.tag_id)
                      : type === TagType.ActivityType
                        ? selectedActivityTypes.map((tag) => tag.tag_id)
                        : type === TagType.Activity
                          ? selectedActivities.map((tag) => tag.tag_id)
                          : selectedEligibleExpenses.map((tag) => tag.tag_id)
                  }
                  onChange={(values) =>
                    handleTagChange(
                      values,
                      type === TagType.Sector
                        ? setSelectedSectors
                        : type === TagType.ActivityType
                          ? setSelectedActivityTypes
                          : type === TagType.Activity
                            ? setSelectedActivities
                            : setSelectedEligibleExpenses,
                      type === TagType.Sector
                        ? sectorOptions.entities
                        : type === TagType.ActivityType
                          ? activityTypeOptions.entities
                          : type === TagType.Activity
                            ? activityOptions.entities
                            : eligibleExpenseOptions.entities
                    )
                  }
                  options={Object.values(
                    type === TagType.Sector
                      ? sectorOptions.entities
                      : type === TagType.ActivityType
                        ? activityTypeOptions.entities
                        : type === TagType.Activity
                          ? activityOptions.entities
                          : eligibleExpenseOptions.entities
                  )?.map((option) => ({
                    label: option.content,
                    value: option.tag_id,
                  }))}
                />
              </Div>
            ))}
          </Div>
        </Collapse.Panel>
      </Collapse>
    );
  };

  return (
    <PageLayout
      app="admin"
      title={"Funding Explorer"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Funding Explorer",
        },
      ]}
      actions={
        <Div flex="row" fitContent>
          <Typography style={{ paddingRight: "8px" }} nowrap>
            {t(`grants|results`)}
          </Typography>
          <Typography bold nowrap>
            {t(`grants|nbGrants`, {
              nb: hasGrants ? grants[0]?.total_count : 0,
            })}
          </Typography>
        </Div>
      }
    >
      <CompaniesSearch handleSubmit={onCompanySelect} />
      {!!company && (
        <FundingExplorerCompanyInfo
          company={company}
          removeCompany={removeCompany}
        />
      )}
      <FundingExplorerListPageLayout
        filterform={filterform}
        onTextQueryChange={onTextQueryChange}
        financingType={financingType}
        industries={industries}
        subIndustries={subindustries}
        provinces={provinces}
        services={services}
        companySize={companySize}
        annualRevenue={annualRevenue}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        selectedIndustries={selectedIndustries}
        setSelectedIndustries={setSelectedIndustries}
        TagsCollapse={TagsCollapse}
        bestProjects={bestProjects}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
      />
      <Div flex="row">
        <Typography style={{ paddingRight: "8px" }}>
          {t(`grants|results`)}
        </Typography>
        <Typography bold>
          {t(`grants|nbGrants`, {
            nb: hasGrants ? grants[0]?.total_count : 0,
          })}
        </Typography>
      </Div>

      {isLoading ? (
        <Loading />
      ) : hasGrants ? (
        grants.map((grant, index) => <GrantCard key={index} {...grant} />)
      ) : (
        <Empty description={t("grants|fundingExplorerFilter.noResult")} />
      )}
      <Div flex="row" justify="center">
        <Pagination
          total={hasGrants ? grants[0]?.total_count : 0}
          current={activePage}
          onChange={(page, pageSize) => onPageChange(page, pageSize)}
        />
      </Div>
    </PageLayout>
  );
};

export default FundingExplorer;

