import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Delete from "@hellodarwin/icons/dist/icons/Delete";
import Save from "@hellodarwin/icons/dist/icons/Save";
import Drawer from "antd/es/drawer";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import React, { ReactNode, useEffect, useState } from "react";
import { isObject } from "../../../../../../features/helpers/arrays-equal";
import { useTheme } from "../../../../../../plugins/styled";
import Button from "../../../../../common/button";
import Div from "../../../../../common/div";
import DragDropSingleColumnContainer from "../../../../../common/drag-drop/single";
import Typography from "../../../../../common/typography";
import FormItem from "../../../../form-layouts/form-item";
import FormLabel from "../../../../form-layouts/form-label";
import { FormEditListItem } from "./styles";

interface FormItemEditInputRender<T> {
  render: (itm: T) => ReactNode;
  addItm?: () => void;
}
interface FormItemEditInput<T> {
  name: string;
  label: string;
  children: ReactNode | FormItemEditInputRender<T>;
}
interface FormItemEditListProps<T> {
  options: T[];
  optionLabel: keyof T;
  optionTitleLabel?: keyof T;
  inputs: FormItemEditInput<T>[];
  typeLabel: string;
  reorder?: (reorderdItems: T[]) => void;
  add?: () => void;
  update?: (updatedItem: T) => void;
  deleteItem?: (deletedItem: T) => void;
  renderLabel?: (key: T[keyof T]) => ReactNode;
  renderTitleLabel?: (key: T[keyof T]) => ReactNode;
  activeElement?: T;
  setActiveElement?: (element: T) => void;
}

const FormItemEditList = <T,>({
  options,
  optionLabel,
  optionTitleLabel,
  inputs,
  typeLabel,
  reorder,
  update,
  renderLabel,
  renderTitleLabel,
  activeElement,
  deleteItem,
  setActiveElement,
}: FormItemEditListProps<T>) => {
  const theme = useTheme();

  const [form] = useForm<T>();
  const [activeItem, setActiveItem] = useState<T>(activeElement);

  const closeDrawer = () => {
    !!setActiveElement ? setActiveElement(undefined) : setActiveItem(undefined);
  };

  const openDrawer = (item: T) =>
    !!setActiveElement ? setActiveElement(item) : setActiveItem(item);

  useEffect(() => {
    setActiveItem(activeElement);
  }, [activeElement]);

  if (!options) return <></>;

  const getLabel = (option: T, isTitle?: boolean) => {
    return !!renderTitleLabel && isTitle
      ? renderTitleLabel(option[optionTitleLabel ?? optionLabel])
      : !!renderLabel
        ? renderLabel(option[optionLabel])
        : (option[optionLabel] as string);
  };

  const addRowToArray = (itm: FormItemEditInput<T>) => {
    const value = form.getFieldValue(itm.name as any);

    form.setFieldValue(itm.name as any, [...value, ""]);
  };

  const removeRowFromArray = (itm: FormItemEditInput<T>, index: number) => {
    const value = form.getFieldValue(itm.name as any);

    delete value[index];
    form.setFieldValue(itm.name as any, value);
  };

  const handleUpdateOption = () => {
    form
      .validateFields()
      .then((values) => {
        const editedValues: T = { ...activeItem, ...values };
        update(editedValues);
      })
      .catch((e) => console.error(e));
  };

  const renderItem = (option: T) => (
    <FormEditListItem onClick={() => openDrawer(option)}>
      <>{getLabel(option, false)}</>
      {!!deleteItem && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            deleteItem(option);
          }}
          trailingIcon={<Delete width={12} height={12} />}
          size="circle"
          defaultStyle={theme.colors.primary}
        />
      )}
    </FormEditListItem>
  );

  useEffect(() => {
    if (!!activeItem && isObject(activeItem)) {
      Object.entries(activeItem).forEach(([key, value]) => {
        form.setFieldValue(key as any, value);
      });
    }
  }, [activeItem]);
  return (
    <Div flex="column" gap={8}>
      {!!reorder ? (
        <DragDropSingleColumnContainer
          setItems={reorder}
          items={options}
          direction="vertical"
          itemStyle={{ padding: 0, marginBottom: 8, width: "100%" }}
          renderItem={renderItem}
        />
      ) : (
        React.Children.toArray(options.map(renderItem))
      )}

      <Drawer
        open={!!activeItem}
        onClose={closeDrawer}
        title={
          !!activeItem && (
            <Div
              align="center"
              flex="row"
              style={{ height: "100%" }}
              justify="space-between"
            >
              <Div fitContent flex="row" gap={8} align="center">
                <Typography elementTheme="subtitle1">{typeLabel} :</Typography>
                <Typography elementTheme="subtitle1">
                  {getLabel(activeItem, true)}
                </Typography>
              </Div>{" "}
              {!!update && (
                <Button
                  size="circle"
                  defaultStyle={theme.colors.white_1}
                  onClick={handleUpdateOption}
                >
                  <Save width={16} height={16} />
                </Button>
              )}
            </Div>
          )
        }
      >
        {!!activeItem && (
          <Form layout="vertical" initialValues={activeItem} form={form}>
            <Div flex="column" gap={16}>
              {React.Children.toArray(
                inputs.map((itm) => {
                  const isArray = Array.isArray(activeItem[itm.name]);
                  const isNode = React.isValidElement(itm.children);
                  return (
                    <FormItem
                      name={itm.name}
                      label={
                        <FormLabel
                          label={itm.label}
                          actions={
                            isArray &&
                            (isNode ? (
                              <Button
                                transparent
                                defaultStyle={theme.colors.primary}
                                onClick={() => addRowToArray(itm)}
                              >
                                <AddNew width={16} height={16} />
                              </Button>
                            ) : (
                              !!(itm.children as FormItemEditInputRender<T>)
                                ?.addItm
                            )) ? (
                              <Button
                                transparent
                                defaultStyle={theme.colors.primary}
                                onClick={() =>
                                  (
                                    itm.children as FormItemEditInputRender<T>
                                  ).addItm()
                                }
                              >
                                <AddNew width={16} height={16} />
                              </Button>
                            ) : undefined
                          }
                        />
                      }
                    >
                      {React.isValidElement(itm.children) ? (
                        isArray ? (
                          <Form.List name={itm.name}>
                            {(fields) => {
                              return (
                                <div>
                                  {React.Children.toArray(
                                    fields.map((field, i) => (
                                      <Div flex="row" gap={8} align="center">
                                        <Form.Item
                                          style={{ margin: 0, width: "100%" }}
                                          {...field}
                                          fieldKey={"trigger_value"}
                                        >
                                          {itm.children as unknown as ReactNode}
                                        </Form.Item>
                                        {!!deleteItem && (
                                          <Button
                                            transparent
                                            defaultStyle={theme.colors.primary}
                                            onClick={() =>
                                              removeRowFromArray(itm, i)
                                            }
                                          >
                                            <Delete width={16} height={16} />
                                          </Button>
                                        )}
                                      </Div>
                                    ))
                                  )}
                                </div>
                              );
                            }}
                          </Form.List>
                        ) : (
                          itm.children
                        )
                      ) : (
                        (itm.children as FormItemEditInputRender<T>).render(
                          activeItem
                        )
                      )}
                    </FormItem>
                  );
                })
              )}
            </Div>
          </Form>
        )}
      </Drawer>
    </Div>
  );
};

export default FormItemEditList;

