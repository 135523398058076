import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";

const GinList = () => {
  return (
    <PageLayout
      app="admin"
      title={"Grants Internal Notes"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants Internal Notes",
        },
      ]}
    >
      Work in construction. Don't ask
    </PageLayout>
  );
};

export default GinList;

