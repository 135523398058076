import PageLayout from "@hellodarwin/core/lib/components/common/layout/page-layout";

const SingleGinPage = () => {
  return (
    <PageLayout
      app="admin"
      title={"Funding Explorer"}
      breadcrumbs={[
        {
          breadcrumbName: "Home",
          path: "/",
        },
        {
          breadcrumbName: "Grants Internal Notes",
          path: "/gin",
        },
        {
          breadcrumbName: "Grant",
        },
      ]}
    ></PageLayout>
  );
};

export default SingleGinPage;

