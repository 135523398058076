import Div from "@hellodarwin/core/lib/components/common/div";
import { Program } from "@hellodarwin/core/lib/features/entities";
import message from "antd/es/message";
import Modal from "antd/es/modal";
import Radio from "antd/es/radio";
import Typography from "antd/es/typography";
import { useState } from "react";
import { useAppDispatch } from "../../app";
import {
  createHubspotDeal,
  updateHubspotDeal,
} from "../../features/api/slices/programs-slice";
import { useAdminApi } from "../../features/api/use-admin-api";
import ProgramsSearch from "./programs-search";

type SelectDealModalProps = {
  program: Program;
  companyId?: string;
  visible: boolean;
  handleClose: () => void;
};

export const SelectDealModal = ({
  program,
  companyId,
  visible,
  handleClose,
}: SelectDealModalProps) => {
  const api = useAdminApi();
  const dispatch = useAppDispatch();
  const [isNewProgram, setIsNewProgram] = useState(false);
  const [programCRMDID, setProgramCRMDID] = useState<string | undefined>();

  const updateProgram = async () => {
    try {
      if (isNewProgram) {
        await dispatch(createHubspotDeal({ api, program }));
      } else {
        const updatedProgram = {
          ...program,
          program_hs_deal_id: programCRMDID,
        };
        await dispatch(updateHubspotDeal({ api, program: updatedProgram }));
      }
      message.success("Deal " + (programCRMDID ? "updated" : "created"));
      handleClose();
    } catch (error) {
      message.error(
        "Error " + (programCRMDID ? "updating" : "creating") + " deal"
      );
      console.error(error);
    }
  };
  return (
    <Modal
      title="Select Deal"
      open={visible}
      keyboard={true}
      okButtonProps={{ disabled: !programCRMDID && !isNewProgram }}
      style={{ padding: "1rem" }}
      onOk={updateProgram}
      onCancel={handleClose}
    >
      <Div flex="column" style={{ marginBottom: 16 }}>
        <Div
          flex="row"
          justify="space-between"
          align="center"
          style={{ marginBottom: 8 }}
        >
          <Typography.Text strong>For: </Typography.Text>
          <Radio.Group
            options={[
              { label: "Existing Program", value: "0" },
              { label: "New Program", value: "1" },
            ]}
            onChange={(e) => {
              setIsNewProgram(e.target.value === "1");
              if (e.target.value === "1") {
                setProgramCRMDID(undefined);
              }
            }}
            value={!companyId ? "1" : isNewProgram ? "1" : "0"}
            optionType="button"
            buttonStyle="solid"
          />
        </Div>
        <ProgramsSearch
          handleSelect={(value) => {
            setProgramCRMDID(value);
          }}
          disabled={isNewProgram}
          companyId={companyId}
        />
      </Div>
    </Modal>
  );
};

export default SelectDealModal;

