import Div from "@hellodarwin/core/lib/components/common/div";
import Typography from "@hellodarwin/core/lib/components/common/typography";
import { AdminGrantResult } from "@hellodarwin/core/lib/features/entities";
import styled from "@hellodarwin/core/lib/plugins/styled";
import AddNew from "@hellodarwin/icons/dist/icons/AddNew";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Carousel from "antd/es/carousel";
import Form from "antd/es/form";
import Select from "antd/es/select";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import {
  fetchGrantProviders,
  selectGrantProviders,
} from "../../../features/api/slices/grants-slice";
import { useAdminApi } from "../../../features/api/use-admin-api";
import { AdminPagesForms } from "../../../pages/single-project-page";
import theme from "../../../theme";
import CreateGrantProviderModal from "../create-grant-provider-modal";
import GrantPromptPropertySection from "../grant-prompt-property-section";
import { GrantFormValues } from "./grant-form";
import GrantFormHeader from "./grant-form-header";

type CreateGrantFormProps = {
  grant?: AdminGrantResult;
  setForms: Dispatch<SetStateAction<AdminPagesForms>>;
  onSave?: () => Promise<void>;
  setIsOnGrantInfo: Dispatch<SetStateAction<boolean>>;
};

const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: black;
  }
  > .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: black;
  }

  > .slick-arrow {
    color: black;
  }
`;

const CreateGrantForm = ({
  grant,
  setForms,
  onSave,
  setIsOnGrantInfo,
}: CreateGrantFormProps) => {
  const [form] = Form.useForm<GrantFormValues>();
  const api = useAdminApi();
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState(false);
  const grantsProviders = useAppSelector(selectGrantProviders);
  useEffect(() => {
    dispatch(fetchGrantProviders({ api, locale: "en" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setForms((prevState: AdminPagesForms) => ({
      ...prevState,
      grantForm: form,
    }));
  }, [form, setForms]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(grant ? grant : {});
  }, [form, grant]);

  const setIsOnGrantInfoState = (index: number) => {
    if (index === 1) {
      setIsOnGrantInfo(true);
    } else {
      setIsOnGrantInfo(false);
    }
  };
  const initialValues: GrantFormValues = {
    grant_title_en: grant?.grant_title_en || "",
    grant_title_fr: grant?.grant_title_fr || "",
    grant_display_title_en: grant?.grant_title_en || "",
    grant_display_title_fr: grant?.grant_title_fr || "",
    description_short_en: grant?.grant_description_short_en || "",
    description_short_fr: grant?.grant_description_short_fr || "",
    description_long_en: grant?.grant_description_long_en || "",
    description_long_fr: grant?.grant_description_long_fr || "",
    expanded_description_en: grant?.expanded_description_en || "",
    expanded_description_fr: grant?.expanded_description_fr || "",
    url_en: grant?.grant_url_en || "",
    url_fr: grant?.grant_url_fr || "",
    grant_urls_en: grant?.grant_urls_en || [""],
    grant_urls_fr: grant?.grant_urls_fr || [""],
    grant_slug_en: grant?.grant_slug_en || "",
    grant_slug_fr: grant?.grant_slug_fr || "",
    youtube_url_en: grant?.grant_youtube_url_en || "",
    youtube_url_fr: grant?.grant_youtube_url_fr || "",
    grant_financing_type: grant?.financing_type || [],
    grant_providers: grant?.providers || [],
    funding_max_amount: grant?.funding_max_amount || undefined,
    funding_min_amount: grant?.funding_min_amount || undefined,
    applicants_min_revenue_past_year:
      grant?.applicants_min_revenue_past_year || undefined,
    applicants_max_revenue_past_year:
      grant?.applicants_max_revenue_past_year || undefined,
    applicants_min_count_employee:
      grant?.applicants_min_count_employee || undefined,
    applicants_max_count_employee:
      grant?.applicants_max_count_employee || undefined,
    grant_created_at: grant?.grant_created_at || undefined,
    grant_updated_at: grant?.grant_updated_at || undefined,
    percentage_funding: grant?.percentage_funding || undefined,
    application_phone_number: grant?.application_phone_number || "",
    application_email_address: grant?.application_email_address || "",
    official_documents: grant?.official_documents || "",
    city: grant?.city || "",
    region: grant?.region || "",
    province: grant?.province || "",
    country: grant?.country || "",
    grant_logo: grant?.grant_logo || "",
    service: grant?.service || [],
    verified: grant?.verified || "Verified",
    eligibility_criteria_en: grant?.eligibility_criteria_en || undefined,
    eligibility_criteria_fr: grant?.eligibility_criteria_fr || undefined,
    eligible_expenses_en: grant?.eligible_expenses_en || undefined,
    eligible_expenses_fr: grant?.eligible_expenses_fr || undefined,
    who_can_apply_en: grant?.who_can_apply_en || undefined,
    who_can_apply_fr: grant?.who_can_apply_fr || undefined,
    who_cannot_apply_en: grant?.who_cannot_apply_en || undefined,
    who_cannot_apply_fr: grant?.who_cannot_apply_fr || undefined,
    project_activity_en: grant?.project_activity_en || undefined,
    project_activity_fr: grant?.project_activity_fr || undefined,
    zone_en: grant?.zone_en || undefined,
    zone_fr: grant?.zone_fr || undefined,
    selection_criteria_en: grant?.selection_criteria_en || undefined,
    selection_criteria_fr: grant?.selection_criteria_fr || undefined,
    steps_how_to_apply_en: grant?.steps_how_to_apply_en || undefined,
    steps_how_to_apply_fr: grant?.steps_how_to_apply_fr || undefined,
    additional_information_en: grant?.additional_information_en || undefined,
    additional_information_fr: grant?.additional_information_fr || undefined,
    notes: grant?.notes || undefined,
  };

  const handleSuccess = async (grantProviderId: string) => {
    const newProviders = form.getFieldValue("grant_providers") || [];
    newProviders.push(grantProviderId);

    form.setFieldValue("grant_providers", newProviders);
    setVisible(false);
  };

  return (
    <>
      <Card style={{ padding: "1rem", background: theme.colors.beige_4 }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          name="grant"
        >
          <CarouselWrapper
            arrows
            infinite={false}
            afterChange={setIsOnGrantInfoState}
          >
            <Div
              id="grant-providers"
              flex="column"
              justify="space-between"
              align="center"
            >
              <Typography.Title textAlign="center" level={2}>
                Select Grant Providers
              </Typography.Title>
              <Div
                flex="row"
                justify="start"
                align="flex-end"
                style={{ marginLeft: "70px", width: "90%" }}
              >
                <Div style={{ width: "90%", marginRight: "10px" }}>
                  <GrantPromptPropertySection
                    margin="0"
                    form={form}
                    label="Grant Providers"
                    section="providers"
                    isMultipleSelect={true}
                    grantsProviders={grantsProviders}
                  />
                </Div>

                <Button
                  icon={<AddNew width={24} height={24} />}
                  onClick={() => setVisible(true)}
                ></Button>
              </Div>
            </Div>
            <Div>
              <Div style={{ width: "95%", marginLeft: "70px" }}>
                <Div flex="row" justify="space-between" align="start">
                  <Form.Item
                    label="Verified"
                    name="verified"
                    style={{ width: "125px" }}
                    rules={[
                      {
                        required: true,
                        message: "Please specified if this grant is verified",
                      },
                    ]}
                  >
                    <Select placeholder="Select option">
                      <Select.Option value="Verified">Verified</Select.Option>
                      <Select.Option value="Rejected">Rejected</Select.Option>
                      <Select.Option value="Suspended">Suspended</Select.Option>
                    </Select>
                  </Form.Item>
                  <Typography.Title level={2}>Grant</Typography.Title>
                </Div>

                <Div style={{ width: "98%" }}>
                  <GrantFormHeader
                    form={form}
                    grant={grant}
                    isCreateGrant={true}
                    onSave={onSave}
                    initialValues={initialValues}
                  ></GrantFormHeader>
                </Div>
              </Div>
            </Div>
          </CarouselWrapper>
        </Form>
      </Card>
      <CreateGrantProviderModal
        visible={visible}
        handleClose={() => {
          setVisible(false);
        }}
        handleSuccess={handleSuccess}
      ></CreateGrantProviderModal>
    </>
  );
};

export default CreateGrantForm;

